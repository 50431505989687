import { gql } from '@apollo/client/core';
import { ACTIVE_REMINDER_FRAGMENT } from '../reminders/queries';

export const ALL_ADMIN_STUDENT_COLUMNS = gql`
  query allAdminStudentColumns {
    allAdminStudentColumns {
      field {
        name
        label
      }
    }
  }
`;

export const ALL_ADMIN_PRECEPTOR_COLUMNS = gql`
  query allAdminPreceptorColumns {
    allAdminPreceptorColumns {
      field {
        name
        label
      }
    }
  }
`;

export const ALL_STUDENTS_EMAILS_QUERY = gql`
  query allStudents($search: String, $activated: String, $wizardCompleted: String, $location: String, $owners: [String], $university: [Int], $programOfStudy: [Int], $statuses: [String], $studentTags: [Int], $phone: String, $suppressEmails: Boolean) {
    allStudents(search: $search, activated: $activated, wizardCompleted: $wizardCompleted, location: $location, owners: $owners, university: $university, programOfStudy: $programOfStudy, statuses: $statuses, tags: $studentTags, phone: $phone, suppressEmails: $suppressEmails) {
      results {
        uuid
        emailAddress
      }
    }
  }
`;

export const ALL_STUDENTS_QUERY = gql`
  query allStudents($first: Int, $skip: Int, $search: String, $activated: String, $wizardCompleted: String, $location: String, $owners: [String], $university: [Int], $programOfStudy: [Int], $statuses: [String], $tags: [Int], $phone: String) {
    allStudents(first: $first, skip: $skip, search: $search, activated: $activated, wizardCompleted: $wizardCompleted, location: $location, owners: $owners, university: $university, programOfStudy: $programOfStudy, statuses: $statuses, tags: $tags, phone: $phone) {
      totalCount
      totalCountInDb
      results {
        uid
        uuid
        firstName
        lastName
        createdAt
        emailAddress
        isWizardCompleted
        email
        note
        tags {
          id
          name
          textColor
          backgroundColor
        }
        status {
          key
          value
        }
        location {
          city
          generalLocation
        }
        owners {
          uuid
          user {
            email
            getName
          }
        }
        profile {
          avatar
          adminColumns {
            name
            value
            label
          }
        }
      }
    }
  }
`;

export const ALL_STUDENTS_AUTOCOMPLETE_QUERY = gql`
  query allStudents {
    allStudents {
      totalCount
      results {
        id
        uid
        firstName
        lastName
        emailAddress
        email
      }
    }
  }
`;

export const ALL_PRECEPTORS_EMAILS_QUERY = gql`
  query allPreceptors($first: Int, $skip: Int, $search: String, $activated: String, $wizardCompleted: String, $location: String, $clinic: String, $specialty: [Int], $owners: [String], $credentials: [Int], $typeOfPractice: [Int], $statuses: [String], $availability: [String], $availabilityByTime: [String], $dateFrom: Date, $dateTo: Date, $hours: Int, $tags: [Int], $compactStates: [String], $phone: String, $honorariumType: Int, $priceFrom: Int, $priceTo: Int, $suppressEmails: Boolean) {
    allPreceptors(first: $first, skip: $skip, search: $search, activated: $activated, wizardCompleted: $wizardCompleted, location: $location, clinic: $clinic, specialty: $specialty, owners: $owners, credentials: $credentials, typeOfPractice: $typeOfPractice, statuses: $statuses, availability: $availability, availabilityByTime: $availabilityByTime, dateFrom: $dateFrom, dateTo: $dateTo, hours: $hours, tags: $tags, compactStates: $compactStates, phone: $phone, honorariumType: $honorariumType, priceFrom: $priceFrom, priceTo: $priceTo, suppressEmails: $suppressEmails) {
      results {
        uuid
        emailAddress
      }
    }
  }
`;

export const ALL_PRECEPTORS_QUERY = gql`
  query allPreceptors($first: Int, $skip: Int, $search: String, $activated: String, $wizardCompleted: String, $location: String, $clinic: String, $specialty: [Int], $owners: [String], $credentials: [Int], $typeOfPractice: [Int], $statuses: [String], $availability: [String], $availabilityByTime: [String], $dateFrom: Date, $dateTo: Date, $hours: Int, $tags: [Int], $compactStates: [String], $phone: String, $honorariumType: Int, $priceFrom: Int, $priceTo: Int, $costForRotation: String, $psych: [Int], $order: String) {
    allPreceptors(first: $first, skip: $skip, search: $search, activated: $activated, wizardCompleted: $wizardCompleted, location: $location, clinic: $clinic, specialty: $specialty, owners: $owners, credentials: $credentials, typeOfPractice: $typeOfPractice, statuses: $statuses, availability: $availability, availabilityByTime: $availabilityByTime, dateFrom: $dateFrom, dateTo: $dateTo, hours: $hours, tags: $tags, compactStates: $compactStates, phone: $phone, honorariumType: $honorariumType, priceFrom: $priceFrom, priceTo: $priceTo, costForRotation: $costForRotation, psych: $psych, order: $order) {
      totalCount
      totalCountInDb
      results {
        uuid
        uid
        firstName
        lastName
        createdAt
        emailAddress
        isWizardCompleted
        email
        note
        availability
        availabilityEndDate
        costForRotation
        tags {
          id
          name
          textColor
          backgroundColor
        }
        status {
          key
          value
        }
        location {
          city
          generalLocation
        }
        clinic {
          name
        }
        owners {
          uuid
          user {
            email
            getName
          }
        }
        availSettings {
          isPublished
        }
        profile {
          avatar
          credentials {
            key
            value
          }
          specialty {
            key
            value
          }
          typeOfPractice {
            key
            value
          }
          averageNumberOfPatientsPerDay4755 {
            key
            value
          }
          studentsPrecepted
          adminColumns {
            name
            value
            label
          }
        }
      }
    }
  }
`;

export const ALL_CLINIC_COORDINATORS = gql`
  query allClinicCoordinators {
    allClinicCoordinators {
      uuid
      user {
        getName
      }
    }
  }
`;

export const CLINIC_COORDINATORS_STATE_OWNERSHIP = gql`
  query allClinicCoordinators {
    allClinicCoordinators {
      uuid
      user {
        getName
      }
    }
    stateOwnership
  }
`;

export const FIELD_GROUP_FRAGMENT = gql`
  fragment fieldGroups on FieldGroupType {
    name
    fields {
      name
      label
      datatype
      value
      displayValue
      options {
        key
        value
      }
    }
  }`;

export const STUDENT_PROFILE = gql`
  ${FIELD_GROUP_FRAGMENT}
  ${ACTIVE_REMINDER_FRAGMENT}
  query student($uuid: String!){
    student(uuid: $uuid) {
      id
      uid
      uuid
      firstName
      lastName
      emailAddress
      isWizardCompleted
      phone
      contentTypeId
      note
      canBeActivated
      suppressEmails
      userUuid
      userId
      activeReminder {
        ...ActiveReminderFragment
      }
      financesNote
      rotations {
        uid
        uuid
      }
      tags {
        id
        name
        textColor
        backgroundColor
      }
      location {
        uuid
        contentTypeId
        country
        state
        generalLocation
        city
        zipCode
        description
      }
      notes {
        uuid
        note
        createdAt
        createdBy {
          uuid
          email
        }
        contentTypeId
      }
      payments {
        uuid
        stripeUid
        paymentAmount
        status {
          key
          value
        }
        paymentMethod {
          brand
          last4
        }
        rotation {
          uid
          uuid
        }
        preceptor {
          uid
          uuid
        }
        description {
          key
          value
        }
        createdAt
      }
      profile {
        uuid
        contentTypeId
        avatar
        apartmentUnitSuiteOrFloor
        fieldGroupsLeft {
          ...fieldGroups
        }
        fieldGroupsRight {
          ...fieldGroups
        }
        account {
          uuid
        }
      }
      owners {
        uuid
        user {
          getName
        }
      }
    }
  }
`;

export const PRECEPTOR_PROFILE = gql`
  ${FIELD_GROUP_FRAGMENT}
  ${ACTIVE_REMINDER_FRAGMENT}
  query preceptor($uuid: String!){
    preceptor(uuid: $uuid) {
      id
      uid
      uuid
      firstName
      lastName
      emailAddress
      isWizardCompleted
      contentTypeId
      note
      canBeActivated
      suppressEmails
      userUuid
      userId
      activeReminder {
        ...ActiveReminderFragment
      }
      financesNote
      rotations {
        uid
        uuid
      }
      tags {
        id
        name
        textColor
        backgroundColor
      }
      location {
        uuid
        contentTypeId
        country
        state
        generalLocation
        city
        zipCode
        description
      }
      owners {
        uuid
        user {
          getName
        }
      }
      notes {
        uuid
        note
        createdAt
        createdBy {
          uuid
          email
        }
        contentTypeId
      }
      availSettings {
        contentTypeId
        uuid
        weekdays
        timepoint {
          key
          value
        }
        days {
          weekday {
            key
            value
          }
          start {
            key
            value
          }
          end {
            key
            value
          }
          hours
        }
        isPublished
      }
      availRanges {
        uuid
        start
        end
        students
        workingRequests {
          status {
            key
            value
          }
          rotation {
            uuid
            uid
            startDate
            endDate
            hours
            status {
              key
              value
            }
            paperworkDueDate
          }
        }
      }
      honorariumHourRanges {
        uuid
        start
        end
        amount
      }
      profile {
        uuid
        contentTypeId
        avatar
        hoursCapPerRotation
        additionalAddress
        timezone {
          key
          value
          options {
            key
            value
          }
        }
        apartmentUnitSuiteOrFloor
        dayshoursYouWork
        clinicBusinessHours
        whenAreYouAvailableToTakeAStudent
        blockedDatesStudentvacation
        howManyStudentsCanYouPreceptPerSemester
        honorariumMailingAddress6491
        apartmentUnitSuiteOrFloor7303
        additionalHonorariumRequirement
        paperworkNote
        honorariumType {
          key
          value
        }
        amount
        minimumHonorarium
        flatFeeForAllHours
        fieldGroupsLeft {
          ...fieldGroups
        }
        fieldGroupsRight {
          ...fieldGroups
        }
        account {
          uuid
        }
      }
    }
  }
`;

export const ALL_PRECEPTOR_DOCUMENTS = gql`
  query allPreceptorDocuments($uuid: String!) {
    allPreceptorDocuments(uuid: $uuid) {
      id
      uuid
      document
      url
      createdAt
    }
  }
`;

export const ALL_STUDENT_SENT_EMAILS = gql`
  query allStudentSentEmails($uuid: String!, $first: Int, $skip: Int) {
    allStudentSentEmails(studentUuid: $uuid, first: $first, skip: $skip) {
      totalCount
      results {
        id
        subject
        senderId
        createdAt
        fromEmail
        to
        body
        htmlMessage
      }
    }
  }
`;

export const ALL_PRECEPTOR_SENT_EMAILS = gql`
  query allPreceptorSentEmails($uuid: String!, $first: Int, $skip: Int) {
    allPreceptorSentEmails(preceptorUuid: $uuid, first: $first, skip: $skip) {
      totalCount
      results {
        id
        subject
        senderId
        createdAt
        fromEmail
        to
        body
        htmlMessage
      }
    }
  }
`;

export const ALL_ROTATION_SENT_EMAILS = gql`
  query allRotationSentEmails($uuid: String!, $first: Int, $skip: Int) {
    allRotationSentEmails(rotationUuid: $uuid, first: $first, skip: $skip) {
      totalCount
      results {
        id
        subject
        senderId
        createdAt
        fromEmail
        to
        body
        htmlMessage
      }
    }
  }
`;

export const ACTION_TYPES = gql`
  query actionTypeOptions {
    actionTypeOptions {
      options {
        key
        value
      }
    }
  }
`;

export const ALL_OBJECT_ACTIONS = gql`
  query allObjectActions($contentTypeId: ID!, $objectId: ID!, $first: Int, $skip: Int, $search: String, $actionTypes: [String]) {
    allObjectActions(contentTypeId: $contentTypeId, objectId: $objectId, first: $first, skip: $skip, search: $search, actionTypes: $actionTypes) {
      totalCount
      results {
        actor {
          getName
          lastUsedRole {
            key
            value
          }
          roledObjectUuid
        }
        verb {
          key
          value
        }
        description
        sentEmail {
          id
          senderId
          to
          fromEmail
          subject
          body
          htmlMessage
          cc
          bcc
          emailAttachments {
            url
            name
            size
          }
          mergeData
        }
        actionObjectName
        targetObjectName
        old
        new
        createdAt
      }
    }
  }
`;
